// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(str) {
  switch (str) {
    case "not_relevant" :
        return "NotRelevant";
    case "relevant" :
        return "Relevant";
    default:
      return ;
  }
}

function serialize(t) {
  if (t === "Relevant") {
    return "relevant";
  } else {
    return "not_relevant";
  }
}

var defaultValue = "Relevant";

export {
  parse ,
  serialize ,
  defaultValue ,
}
/* No side effect */
