// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Sonner from "../components/ui/Sonner.mjs";
import * as Tooltip from "../components/ui/Tooltip.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RelayRouter from "rescript-relay-router/src/RelayRouter.mjs";
import * as ErrorBoundary from "./components/ErrorBoundary.mjs";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as PendingIndicatorBar from "./components/PendingIndicatorBar.mjs";

function App(props) {
  return JsxRuntime.jsx(Tooltip.Provider.make, {
              children: Caml_option.some(JsxRuntime.jsx(RescriptRelay.Context.Provider.make, {
                        environment: props.environment,
                        children: JsxRuntime.jsx(RelayRouter.Provider.make, {
                              value: props.routerContext,
                              children: JsxRuntime.jsx(React.Suspense, {
                                    children: Caml_option.some(JsxRuntime.jsxs(ErrorBoundary.make, {
                                              children: [
                                                JsxRuntime.jsx(RelayRouter.RouteRenderer.make, {
                                                      renderPending: (function (pending) {
                                                          return JsxRuntime.jsx(PendingIndicatorBar.make, {
                                                                      pending: pending
                                                                    });
                                                        })
                                                    }),
                                                JsxRuntime.jsx(Sonner.Toaster.make, {})
                                              ],
                                              fallback: (function (param) {
                                                  return JsxRuntime.jsx("div", {
                                                              children: "Error!"
                                                            });
                                                })
                                            })),
                                    fallback: "Loading..."
                                  })
                            })
                      }))
            });
}

var make = App;

export {
  make ,
}
/* react Not a pure module */
