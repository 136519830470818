// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as GraphqlSse from "graphql-sse";
import * as Core__Option from "@rescript/core/src/Core__Option.mjs";
import * as RelayRuntime from "relay-runtime";

async function fetchQuery(operation, variables, _cacheConfig, _uploads) {
  var res = await fetch(import.meta.env.VITE_GRAPHQL_API_ENDPOINT, {
        method: "POST",
        body: Caml_option.some(Core__Option.getOr(JSON.stringify({
                      query: operation.text,
                      variables: variables
                    }), "")),
        headers: Caml_option.some(new Headers([[
                    "content-type",
                    "application/json"
                  ]])),
        credentials: "same-origin"
      });
  if (res.ok) {
    return await res.json();
  } else {
    return Js_exn.raiseError("API error.");
  }
}

var sseClient = GraphqlSse.createClient({
      url: import.meta.env.VITE_GRAPHQL_API_ENDPOINT,
      shouldRetry: (function (_event) {
          return true;
        })
    });

function subscriptionFunction(operation, variables, _cacheConfig) {
  var subscriptionQuery_operationName = operation.name;
  var subscriptionQuery_query = operation.text;
  var subscriptionQuery = {
    operationName: subscriptionQuery_operationName,
    query: subscriptionQuery_query,
    variables: variables
  };
  return RelayRuntime.Observable.create(function (sink) {
              var unsubscribe = sseClient.subscribe(subscriptionQuery, sink);
              return {
                      unsubscribe: unsubscribe,
                      closed: false
                    };
            });
}

export {
  fetchQuery ,
  sseClient ,
  subscriptionFunction ,
}
/* sseClient Not a pure module */
