// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function PendingIndicatorBar(props) {
  return JsxRuntime.jsx("div", {
              children: null,
              className: "fixed left-0 top-0 w-full pointer-events-none z-20"
            });
}

var make = PendingIndicatorBar;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
